<template>
  <BaseLayout>
    <template #header>
      <h2>Daftar Penerimaan Labu</h2>
      <div class="mt-3 d-flex justify-content-between align-items-end">
        <p class="hp-p1-body mb-0 text-black">Semua: ({{ table.total }})</p>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </template>
    <template #content>
      <b-table
        :items="table.items"
        :fields="table.fields"
        :busy="table.isBusy"
        :per-page="table.perPage"
        striped
        responsive
        show-empty
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.status === 'Sudah diterima' ? 'success' : 'warning'
            "
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-button-group>
            <b-button
              variant="info"
              :to="`/lab/${divisionsMode}/blood-received/add/${data.item.mu_name}/${data.item.date}`"
            >
              <i class="ri-stethoscope-fill mr-1"></i>
              Detail</b-button
            >
            <b-button
              variant="warning"
              :to="`/lab/${divisionsMode}/blood-received/print/${data.item.mu_name}/${data.item.date}`"
            >
              <i class="ri-printer-fill mr-1"></i>
              Cetak</b-button
            >
          </b-button-group>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @change="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </template>
  </BaseLayout>
</template>

<script>
import {
  BBadge,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination, BSpinner,
  BTable,
} from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../../api/blood_shipment/manageBloodShipmentAPI";
import { debounce } from "lodash";

export default {
  name: "Penerimaan Darah",
  components: {
    BSpinner,
    BButton,
    BTable,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BBadge,
    BPagination,
  },
  data() {
    return {
      divisionsMode: null,
      table: {
        fields: [
          {
            key: "no",
            label: "Nomor",
            sortable: true,
          },
          {
            key: "date",
            label: "Tanggal",
            sortable: true,
          },
          {
            key: "mu_name",
            label: "Mobil Unit",
            sortable: true,
          },
          {
            key: "count_blood_received",
            label: "Total Diterima",
            sortable: true,
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
          {
            key: "action",
            label: "Aksi",
          },
        ],
        items: [],
        search: "",
        total: null,
        perPage: 15,
        currentPage: 1,
        isBusy: false,
      },
      searchDebounce: null,
    };
  },
  methods: {
    async fetchData() {
      this.table.isBusy = true;
      try {
        const modeAPIs = {
          processing: manageBloodShipmentAPI.getAllDeliveryProcessing,
          IMLTD: manageBloodShipmentAPI.getAllDeliveryIMLTD,
          KGD: manageBloodShipmentAPI.getAllDeliveryKGD,
        };

        const apiMethod = modeAPIs[this.divisionsMode];
        if (apiMethod) {
          const { data: response } = await apiMethod({
            per_page: this.table.perPage,
            page: this.table.currentPage,
            search: this.table.search,
          });

          this.table.items = response.data.data;
          this.table.total = response.data.total;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.table.isBusy = false;
      }
    },
    setInitialDivisionsMode() {
      if (this.$route.path.includes("processing")) {
        this.divisionsMode = "processing";
      } else if (this.$route.path.includes("IMLTD")) {
        this.divisionsMode = "IMLTD";
      } else if (this.$route.path.includes("KGD")) {
        this.divisionsMode = "KGD";
      }
    },
    debounceSearch: debounce(function () {
      this.table.currentPage = 1;
      this.fetchData();
    }, 500),
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
  },
  mounted() {
    this.setInitialDivisionsMode();
    this.fetchData();
    // Generate dummy data
    this.table.items = Array.from({ length: 100 }, (_, index) => ({
      no: index + 1,
      date: `2024-09-${(index % 30) + 1}`,
      mu_name: `MU ${index + 1}`,
      count_blood_received: Math.floor(Math.random() * 100) + 1,
      status: index % 2 === 0 ? "Sudah diterima" : "Belum diterima",
    }));
    this.table.total = this.table.items.length; // Set total items
  },
  watch: {
    $route() {
      const previousMode = this.divisionsMode;
      this.setInitialDivisionsMode();

      if (this.divisionsMode !== previousMode) {
        this.fetchData();
      }
    },
  },
};
</script>
