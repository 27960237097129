import API from "../services/services";

class ManageBloodShipmentAPI {
  checkingBarcode(payload) {
    return API.post("aftap_examination/cheking_barcode", payload);
  }

  getAllShipments(params) {
    return API.get(`blood_delivery/list`, { params });
  }

  detailShipment(params) {
    return API.get(`blood_delivery/detail`, { params });
  }

  addBloodShipment(payload) {
    return API.post("blood_delivery/store", payload);
  }
  getDeliveryProcessing(params) {
    return API.get("blood_received/processing/list", { params });
  }
  getDeliveryIMLTD(params) {
    return API.get("blood_received/screening/list", { params });
  }
  getDeliveryKGD(params) {
    return API.get("blood_received/kgd/list", { params });
  }
  addReceivedProcessing(payload) {
    return API.post("blood_received/processing/list/received", payload);
  }
  addReceivedIMLTD(payload) {
    return API.post("blood_received/screening/list/received", payload);
  }
  addReceivedKGD(payload) {
    return API.post("blood_received/kgd/list/received", payload);
  }
  getAllDeliveryProcessing(params) {
    return API.get("blood_received/processing", { params });
  }
  getAllDeliveryIMLTD(params) {
    return API.get("blood_received/screening", { params });
  }
  getAllDeliveryKGD(params) {
    return API.get("blood_received/kgd", { params });
  }
}

export default new ManageBloodShipmentAPI();
